import { connect } from 'react-redux'
import { StoreState } from 'types'

const mapStateToProps = ({
  appConfig: {
    widget: { killGoogleAutocomplete },
  },
}: StoreState) => {
  return {
    killGoogleAutocomplete,
  }
}

export default connect(mapStateToProps)
