import { AnyAction } from 'redux'
import { types as apiTypes, Place } from '@ballotready/redux-api-client'
import { compareByMtfccAndGeoID } from 'utils/requestBallotUtils'

const initialState: Place | null = null

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case apiTypes.RECEIVE_BALLOT_PLACES:
      const places = action.payload
      places.sort(compareByMtfccAndGeoID)
      return places.find((p: Place) => p.vbm_receiver) || null
    default:
      return state
  }
}
