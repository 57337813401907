import React, { useState } from 'react'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { useTranslation } from 'react-i18next'
import Button from 'components/sparkles/Button/Button'
import emailBallotButtonContainer from './emailBallotButtonContainer'
import EmailBallotForm from './EmailBallotForm/EmailBallotForm'
import Modal from 'components/sparkles/Modal/Modal'
import Notification from 'components/sparkles/Notification/Notification'
import './EmailBallotButton.css'
import { Selection } from '@ballotready/redux-api-client'

interface Props {
  selections: Selection[]
  variant?: string
}

const EmailBallotButton: React.FC<Props> = ({
  selections,
  children,
  variant = 'default',
}) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = () => setIsModalOpen(false)

  return (
    <div>
      <Button
        className="EmailBallotButton"
        icon={faEnvelope}
        onClick={() => setIsModalOpen(true)}
        outline
        size="sm"
        variant={variant}
      >
        {children}
      </Button>

      <Modal
        appElement={document.getElementById('root')}
        className="EmailBallotModal"
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        <h1>{t('EmailBallotButton.modalTitle')}</h1>
        {selections?.length === 0 ? (
          <Notification variant="warning">
            <p>{t('EmailBallotButton.noSelection')}</p>
          </Notification>
        ) : (
          <>
            <p>{t('EmailBallotButton.modalExplanation')}</p>
            <EmailBallotForm onSubmit={closeModal} />
          </>
        )}
        <span>
          <a href="https://www.civicengine.com/terms-of-use">
            {t('EmailBallotButton.termsOfUse')}
          </a>{' '}
          <a href="https://www.civicengine.com/privacy-policy">
            {t('EmailBallotButton.privacyPolicy')}
          </a>
        </span>
      </Modal>
    </div>
  )
}

export default emailBallotButtonContainer(EmailBallotButton)
