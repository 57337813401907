import React from 'react'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'

import Button, { Props as ButtonProps } from '../sparkles/Button/Button'
import signOutButtonContainer from './signOutButtonContainer'

export interface Props extends ButtonProps, RouteComponentProps {
  homeUrl: string | null
}

const SignOutButton: React.FC<Props> = ({
  homeUrl,
  onClick,

  // Extract from props so it's not passed to Button
  staticContext,

  ...props
}) => <Button onClick={() => onClick && onClick(homeUrl)} {...props} />

export default withRouter(signOutButtonContainer(SignOutButton))
