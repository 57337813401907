export default values => async (_, getState) => {
  const {
    auth: { user },
  } = getState()
  const ballotApiUrl = process.env.REACT_APP_EMAIL_BALLOT_API_URL

  await fetch(`${ballotApiUrl}/ballots/${user.ballot_id}/email`, {
    method: 'POST',
    body: JSON.stringify({
      email: values.email,
      et: user.engine_token,
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}
