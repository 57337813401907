import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Button from '../../sparkles/Button/Button'
import LeadForm from '../../LeadForm/LeadForm'
import Modal from '../../sparkles/Modal/Modal'
import './IneligibleModal.css'

const IneligibleModal = ({ onClose }) => {
  const [didSubmit, setDidSubmit] = useState(false)
  const [isModalOpen, setIsmodalOpen] = useState(true)
  const { t } = useTranslation()

  const handleClose = () => {
    setIsmodalOpen(false)
    onClose && onClose()
  }

  return (
    <Modal
      appElement={document.getElementById('root')}
      className="IneligibleModal"
      isOpen={isModalOpen}
      onClose={handleClose}
    >
      {didSubmit ? (
        <div>
          <h1>{t('AddressForm.IneligibleModal.submittedTitle')}</h1>
          <p>{t('AddressForm.IneligibleModal.submittedMessage')}</p>
          <Button onClick={handleClose} outline variant="success">
            {t('AddressForm.IneligibleModal.close')}
          </Button>
        </div>
      ) : (
        <div>
          <h1>{t('AddressForm.IneligibleModal.title')}</h1>
          <p>{t('AddressForm.IneligibleModal.message')}</p>
          <LeadForm
            askAddress
            className="Ineligiblemodal__LeadForm"
            onSubmit={() => setDidSubmit(true)}
          />
        </div>
      )}
    </Modal>
  )
}

IneligibleModal.propTypes = {
  onClose: PropTypes.func,
}

export default IneligibleModal
