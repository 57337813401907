import { connect } from 'react-redux'

import fetchBallotPositionCandidates from 'actions/fetchBallotPositionCandidates'
import selectCandidate from 'actions/selectCandidate'
import selectIssue from 'actions/selectIssue'
import selectMeasure from 'actions/selectMeasure'
import selectPosition from 'actions/selectPosition'
import { StoreState } from 'types'
import { Props } from './BallotLink'
import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'
import { Candidate, Position } from '@ballotready/redux-api-client'

const mapStateToProps = (
  { auth: { user }, currentCandidate, currentPosition }: StoreState,
  { candidate, history, issue, measure, position, to }: Partial<Props>,
): { to: any; currentCandidate: Candidate; currentPosition: Position } => {
  let pathname = null

  if (measure) {
    pathname = `/v/measures/${measure.slug ? measure.slug : measure.id}`
  } else if (position && candidate) {
    pathname = `/v/positions/${
      position.slug ? position.slug : position.id
    }/candidates/${candidate.slug ? candidate.slug : candidate.id}`
  } else if (position && issue) {
    pathname = `/v/positions/${
      position.slug ? position.slug : position.id
    }/issues/${issue.id}`
  } else if (position) {
    pathname = `/v/positions/${position.slug ? position.slug : position.id}`
  } else {
    pathname = to
  }

  return {
    to: { pathname, search: user.ballot_id ? null : history?.location?.search },
    currentPosition,
    currentCandidate,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, void, Action>,
  { candidate, issue, measure, position }: Props,
) => ({
  onClick: () => {
    dispatch(selectCandidate(candidate))
    dispatch(selectIssue(issue))
    dispatch(selectMeasure(measure))
    dispatch(selectPosition(position))

    if (position) {
      dispatch(fetchBallotPositionCandidates(position.id))
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)
