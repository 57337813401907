import React from 'react'
import { Helmet } from 'react-helmet'
import { Route, Switch, withRouter } from 'react-router'
import Loadable from 'react-loadable'
import LoadingComponent from './LoadingComponent/LoadingComponent'
import mainContainer from './mainContainer'
import NotFoundScene from '../NotFoundScene/NotFoundScene'
import SocialHelmet from './SocialHelmet/SocialHelmet'
import DebugPanel from './DebugPanel/DebugPanel'

const AsyncDashboardApp = Loadable({
  loader: () => import('../dashboard/DashboardApp/DashboardApp'),
  loading: LoadingComponent,
})

const AsyncDirectoryApp = Loadable({
  loader: () => import('../directory/DirectoryApp/DirectoryApp'),
  loading: LoadingComponent,
})

const AsyncCheckRegistrationApp = Loadable({
  loader: () =>
    import('../check_registration/CheckRegistrationApp/CheckRegistrationApp'),
  loading: LoadingComponent,
})

const AsyncElectionCenter = Loadable({
  loader: () =>
    import('../election_center/ElectionCenterApp/ElectionCenterApp'),
  loading: LoadingComponent,
})

const AsyncHomeApp = Loadable({
  loader: () => import('../home/HomeApp/HomeApp'),
  loading: LoadingComponent,
})

const AsyncMaptvApp = Loadable({
  loader: () => import('../maptv/MaptvApp/MaptvApp'),
  loading: LoadingComponent,
})

const AsyncRequestBallotApp = Loadable({
  loader: () =>
    import('../request_ballot/RequestBallotApp/RequestBallotApp.tsx'),
  loading: LoadingComponent,
})

const AsyncPledgeApp = Loadable({
  loader: () => import('../pledge/PledgeApp/PledgeApp'),
  loading: LoadingComponent,
})

const AsyncSharedBallotApp = Loadable({
  loader: () => import('../shared_ballot/SharedBallotApp/SharedBallotApp'),
  loading: LoadingComponent,
})

const AsyncSlateCardApp = Loadable({
  loader: () => import('../slate/SlateApp/SlateApp'),
  loading: LoadingComponent,
})

const AsyncSuperSlateCardApp = Loadable({
  loader: () => import('../super_slate/SuperSlateApp/SuperSlateApp'),
  loading: LoadingComponent,
})

const AsyncVoterGuideApp = Loadable({
  loader: () => import('../voter_guide/VoterGuideApp/VoterGuideApp'),
  loading: LoadingComponent,
})

const AsyncWidgetApp = Loadable({
  loader: () => import('../widget/WidgetApp/WidgetApp'),
  loading: LoadingComponent,
})

const AsyncSandboxApp = Loadable({
  loader: () => import('../sandbox/SandboxApp'),
  loading: LoadingComponent,
})

class Main extends React.Component {
  render() {
    const {
      appConfig: {
        debug,
        enableDashboard,
        enableElectionCenter,
        enableMaptv,
        enablePledge,
        enableShareableBallots,
        enableSlateCard,
        enableSuperSlateCard,
        enableRequestBallot,
        enableVoterGuide,
        siteTitle,
        socialGraph,
        tenant,
      },
      usStates,
    } = this.props

    return (
      <div className="Main">
        <Helmet>
          <title>
            {siteTitle} - {tenant.name}
          </title>
          {process.env.REACT_APP_INTERCOM_APP_ID && (
            <script>{`window.intercomSettings = { app_id: "${process.env.REACT_APP_INTERCOM_APP_ID}" };`}</script>
          )}
          {process.env.REACT_APP_INTERCOM_APP_ID && (
            <script>{`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}</script>
          )}
        </Helmet>

        <SocialHelmet attributes={socialGraph} />

        {debug && <DebugPanel />}

        <Switch>
          {enableShareableBallots && (
            <Route path="/sb/:shareToken" component={AsyncSharedBallotApp} />
          )}
          {enableSlateCard && <Route path="/s" component={AsyncSlateCardApp} />}
          {enableSuperSlateCard && (
            <Route path="/" component={AsyncSuperSlateCardApp} />
          )}
          {enableVoterGuide && (
            <Route path="/v" component={AsyncVoterGuideApp} />
          )}
          {enableMaptv && <Route path="/m" component={AsyncMaptvApp} />}
          {enableRequestBallot && (
            <Route path="/request_ballot" component={AsyncRequestBallotApp} />
          )}
          {enableMaptv && (
            <Route
              path="/check_registration"
              component={AsyncCheckRegistrationApp}
            />
          )}
          {enablePledge && <Route path="/pledge" component={AsyncPledgeApp} />}
          <Route path="/w" component={AsyncWidgetApp} />
          {!enableSuperSlateCard && (
            <Route exact path="/" component={AsyncHomeApp} />
          )}
          <Route path="/u" component={AsyncHomeApp} />
          {enableDashboard && (
            <Route path="/dashboard" component={AsyncDashboardApp} />
          )}
          {enableElectionCenter && (
            <Route path="/ec" component={AsyncElectionCenter} />
          )}
          {usStates && (
            <Route
              path={`/(${usStates.map(s => s.short_name).join('|')})(.*)`}
              component={AsyncDirectoryApp}
            />
          )}
          {debug && <Route path="/sandbox" component={AsyncSandboxApp} />}

          <Route component={NotFoundScene} />
        </Switch>
      </div>
    )
  }
}

export default mainContainer(withRouter(Main))
