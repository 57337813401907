import { connect } from 'react-redux'

import toggleLeadBar from 'actions/toggleLeadBar'
import toggleShareableBallotBanner from 'actions/toggleShareableBallotBanner'

const mapStateToProps = (
  {
    appConfig: {
      authEnabled,
      ballotEmailEnabled,
      ballotPrintEnabled,
      customButton,
      enableMaptv,
      enableVoterRegistration,
      globalAlert,
      logoUrl,
      enableElectionCenter,
      enableLeadBar,
      enableShareableBallots,
      universe: { logoLinkUrl },
    },
    auth,
    ballot,
    browser,
    lead,
    showGlobalAlert,
    showLeadBar,
    showShareableBallotBanner,
  },
  { homePath },
) => ({
  auth,
  authEnabled,
  ballot,
  ballotEmailEnabled,
  ballotPrintEnabled,
  customButton,
  enableMaptv,
  enableVoterRegistration,
  globalAlert,
  isCvg: homePath === '/v',
  isSmallScreen: browser.lessThan.medium,
  lead,
  logoLinkUrl,
  logoUrl,
  showECNavigation: enableElectionCenter,
  showGlobalAlert,
  showLeadBar: enableLeadBar && showLeadBar && ballot,
  showShareableBallotBanner:
    enableShareableBallots && showShareableBallotBanner && auth.isSignedIn,
})

const mapDispatchToProps = dispatch => ({
  onLeadBarClose: () => dispatch(toggleLeadBar(false)),
  onShareableBallotBannerClose: () =>
    dispatch(toggleShareableBallotBanner(false)),
})

export default connect(mapStateToProps, mapDispatchToProps)
