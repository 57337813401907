import { AnyAction } from 'redux'
import {
  SELECT_ELECTION_CENTER_SECTION,
  COMPLETE_SECTION,
} from 'actions/constants'
import {
  ElectionCenterSection,
  types as apiTypes,
} from '@ballotready/redux-api-client'

interface ElectionCenterState {
  completedSections: ElectionCenterSection[]
  currentSection: ElectionCenterSection | null
}

const initialState: ElectionCenterState = {
  completedSections: ['address'],
  currentSection: null,
}

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SELECT_ELECTION_CENTER_SECTION:
      return { ...state, currentSection: action.payload }
    case COMPLETE_SECTION: {
      if (!state.completedSections.find(value => value === action.payload)) {
        return {
          ...state,
          completedSections: state.completedSections.concat(action.payload),
        }
      }
      return state
    }
    case apiTypes.RECEIVE_BALLOT:
      return {
        ...state,
        completedSections: action.payload.voter_progress
          ?.electionCenterSections || ['address'],
      }
    default:
      return state
  }
}
