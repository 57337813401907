import { connect } from 'react-redux'

import triggerBallotEmail from '../../../actions/triggerBallotEmail'

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch, { onSubmit }) => ({
  onSubmit: async values => {
    await dispatch(triggerBallotEmail(values))

    if (onSubmit) {
      onSubmit(values)
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)
