import React from 'react'
import classNames from 'classnames'
import PlacesAutocomplete from 'react-places-autocomplete'

import PoweredByGoogleImg from '../../../assets/images/powered_by_google_on_white_hdpi.png'
import './PlaceField.css'
import placeFieldContainer from './placeFieldContainer'

interface Props {
  className: string
  field: { name: string; value: string }
  form: { setFieldValue(name: string, value: string): void }
  googleCallbackName: string
  hint: string
  inputClassName: string
  killGoogleAutocomplete: boolean
  placeholder: string
  required: boolean
}

const PlaceField = ({
  className,
  field: { name, value },
  form: { setFieldValue },
  googleCallbackName,
  hint,
  inputClassName,
  killGoogleAutocomplete,
  placeholder,
  required,
}: Props) => {
  return (
    <PlacesAutocomplete
      debounce={500}
      googleCallbackName={googleCallbackName}
      onChange={address => setFieldValue(name, address)}
      searchOptions={{ sessionToken: (window as any).googleMapsSessionToken }}
      shouldFetchSuggestions={value.length > 6 && !killGoogleAutocomplete}
      value={value}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div className={classNames('PlaceField', className)}>
          <div className="PlaceField__input-container">
            <input
              {...getInputProps({
                'aria-label': 'Address',
                className: classNames('PlaceField__input', inputClassName),
                name,
                placeholder,
                required,
              })}
            />
            {suggestions.length > 0 && (
              <div
                className="PlaceField__dropdown-container"
                data-iframe-height
              >
                {suggestions.map((suggestion, i) => (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className: classNames('PlaceField__suggestion', {
                        'PlaceField__suggestion--active': suggestion.active,
                      }),
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                ))}
                <div className="PlaceField__dropdown-footer">
                  <img alt="Powered by Google" src={PoweredByGoogleImg} />
                </div>
              </div>
            )}
          </div>

          {hint && (
            <small className="PlaceField__Hint">
              <strong>TIP!</strong> {hint}
            </small>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default placeFieldContainer(PlaceField)
