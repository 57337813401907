export const SELECT_CANDIDATE = 'SELECT_CANDIDATE'
export type SELECT_CANDIDATE = typeof SELECT_CANDIDATE

export const SELECT_ISSUE = 'SELECT_ISSUE'
export type SELECT_ISSUE = typeof SELECT_ISSUE

export const SELECT_MEASURE = 'SELECT_MEASURE'
export type SELECT_MEASURE = typeof SELECT_MEASURE

export const SELECT_POSITION = 'SELECT_POSITION'
export type SELECT_POSITION = typeof SELECT_POSITION

export const TOGGLE_GLOBAL_ALERT = 'TOGGLE_GLOBAL_ALERT'
export const TOGGLE_LEAD_BAR = 'TOGGLE_LEAD_BAR'
export const TOGGLE_SHAREABLE_BALLOT_BANNER = 'TOGGLE_SHAREABLE_BALLOT_BANNER'
export const TOGGLE_SIGN_UP_PROMPT = 'TOGGLE_SIGN_UP_PROMPT'

// Ballot search
export const PING_BALLOT = 'PING_BALLOT'
export const PING_BALLOT_STOP = 'PING_BALLOT_STOP'

// Candidate Comments
export const SENT_CANDIDATE_COMMENT = 'SENT_CANDIDATE_COMMENT'

// MAPTV Flow
export const BEGIN_IN_PERSON_FLOW = 'BEGIN_IN_PERSON_FLOW'
export const SELECT_FLOW = 'SELECT_FLOW'
export const SELECT_MAIL_FLOW = 'SELECT_MAIL_FLOW'
export const SET_ALL_MAPTV_OPTIONS = 'SET_ALL_MAPTV_OPTIONS'
export const SELECT_IF_BALLOT_NEEDED = 'SELECT_IF_BALLOT_NEEDED'
export const SAVE_ABSENTEE_BALLOT_FORM = 'SAVE_ABSENTEE_BALLOT_FORM'
export const SAVE_ABSENTEE_BALLOT_REMINDER = 'SAVE_ABSENTEE_BALLOT_REMINDER'

// Request Ballot Flow
export const BEGIN_REQUEST_FLOW = 'BEGIN_REQUEST_FLOW'
export const ACQUIRED_FORM = 'ACQUIRED_FORM'
export const DISMISS_AUTOMATIC_VBM_ALERT = 'DISMISS_AUTOMATIC_VBM_ALERT'
export const REQUEST_IN_PERSON_REMINDERS = 'REQUEST_IN_PERSON_REMINDERS'
export const USE_FILLED_FORM = 'USE_FILLED_FORM'
export const SET_ATTEMPTING_FORM_SUBMIT = 'SET_ATTEMPTING_FORM_SUBMIT'
export const BEGIN_RETURN_FLOW = 'BEGIN_RETURN_FLOW'

// Check Registration
export const REQUESTING_BALLOT = 'REQUESTING_BALLOT'
export const SELECT_IF_REGISTERED = 'SELECT_IF_REGISTERED'
export const SELECT_REGISTRATION_FAILED_OPTION =
  'SELECT_REGISTRATION_FAILED_OPTION'

// Request Ballot Printing Flow
export const TOGGLE_PRINT_BALLOT_REQUEST_STEP =
  'TOGGLE_PRINT_BALLOT_REQUEST_STEP'
export const PRINT_BALLOT_REQUEST_SUBMITTED = 'PRINT_BALLOT_REQUEST_SUBMITTED'
export const ID_UPLOAD_REQUIRED = 'ID_UPLOAD_REQUIRED'
export const REMOVE_UPLOADED_ID = 'REMOVE_UPLOADED_ID'

// Notifications
export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION'

// Polling Days
export const SELECT_POLLING_DAY = 'SELECT_POLLING_DAY'

// Polling Places
export const SELECT_POLLING_PLACE = 'SELECT_POLLING_PLACE'

// Polling Time
export const SELECT_DATE_TIME = 'SELECT_DATE_TIME'

// Reminders
export const ADD_REMINDER_CHANNEL = 'ADD_REMINDER_CHANNEL'

// Step mgmt
export const PUSH_STEPS = 'PUSH_STEPS'
export const POP_STEP = 'POP_STEP'
export const PUSH_STEP = 'PUSH_STEP'

// Super Slate Card
export const FILTER_TAG = 'FILTER_TAG'

// Super Slate Card
export const APPLY_FILTERS = 'APPLY_FILTERS'
export type APPLY_FILTERS = typeof APPLY_FILTERS

export const CLEAR_FILTER = 'CLEAR_FILTER'
export type CLEAR_FILTER = typeof CLEAR_FILTER

export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export type CLEAR_FILTERS = typeof CLEAR_FILTERS

// Election center
export const SELECT_ELECTION_CENTER_SECTION = 'SELECT_ELECTION_CENTER_SECTION'
export const COMPLETE_SECTION = 'COMPLETE_SECTION'

export const TOGGLE_AUTH_MODAL_OPEN = 'TOGGLE_AUTH_MODAL_OPEN'
