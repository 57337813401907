import { types as apiTypes } from '@ballotready/redux-api-client'
import * as types from 'actions/constants'

export default (state = null, action) => {
  switch (action.type) {
    case types.SELECT_MEASURE:
      return action.measure || null

    // When receiving a list of measures update locally selected measure with
    // measure from API.
    case apiTypes.RECEIVE_MEASURES:
      if (state) {
        return (
          action.payload.find(
            measure => measure.slug === state.slug || measure.id === state.id,
          ) || null
        )
      } else {
        return state
      }

    default:
      return state
  }
}
