import React from 'react'
import { Field, FieldConfig, FormikProps } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/pro-regular-svg-icons/faCheckSquare'
import { faSquare } from '@fortawesome/pro-regular-svg-icons/faSquare'
import styles from './Checkbox.module.css'
import classNames from 'classnames'

export interface Props {
  children: React.ReactChildren
  defaultChecked: boolean
  field: FieldConfig
  form: FormikProps<any>
  id: string
  className: string
}

const Checkbox = ({
  children,
  defaultChecked,
  field,
  form: { errors, touched },
  id,
  className,
  ...props
}: Props) => (
  <div className={classNames(styles.checkboxContainer, className)}>
    <label htmlFor={id}>
      <FontAwesomeIcon
        className={styles.checkboxIcon}
        icon={field.value ? faCheckSquare : faSquare}
      />
      {children}
    </label>
    <Field
      className={styles.checkbox}
      defaultChecked={defaultChecked}
      id={id}
      type="checkbox"
      {...field}
      {...props}
    />
    {touched[field.name] && errors[field.name] && (
      <div className="error">{errors[field.name]}</div>
    )}
  </div>
)

export default Checkbox
