import React from 'react'
import classNames from 'classnames'
import { RouteComponentProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt'
import { faPrint } from '@fortawesome/pro-light-svg-icons/faPrint'
import { faShare } from '@fortawesome/pro-light-svg-icons/faShare'
import AddressModal from '../AddressModal/AddressModal'
import AuthModal from '../AuthModal/AuthModal'
import BallotLink from '../BallotLink/BallotLink'
import Button from '../sparkles/Button/Button'
import EmailBallotButton from '../EmailBallotButton/EmailBallotButton'
import LeadBar from '../LeadBar/LeadBar'
import localNavContainer from './localNavContainer'
import ElectionCenterMobileNavButton from '../election_center/ElectionCenterNavigation/ElectionCenterMobileNavButton/ElectionCenterMobileNavButton'
import Logo from '../sparkles/Logo/Logo'
import Markdown from '../sparkles/Markdown/Markdown'
import Notification from '../sparkles/Notification/Notification'
import SignOutButton from '../SignOutButton/SignOutButton'
import './LocalNav.css'
import { Ballot } from '@ballotready/redux-api-client'
import useAnalytics from 'hooks/useAnalytics'

export interface Props extends RouteComponentProps {
  alert?: string | null
  auth?: any // TODO type
  authEnabled?: boolean
  backButton?: boolean
  ballot?: Ballot
  ballotEmailEnabled?: boolean
  ballotPrintEnabled?: boolean
  center?: boolean
  className?: string
  customButton?: {
    title: string
    url: string
  }
  enableMaptv?: boolean
  enableVoterRegistration?: boolean
  onAlertClose?: () => void
  onLeadBarClose: () => void
  onShareableBallotBannerClose: () => void
  onUpdateAddress?: () => void
  homePath?: string
  isCvg?: boolean
  isSmallScreen?: boolean
  lead?: any // TODO Type
  logoLinkUrl?: string
  logoUrl?: string
  showAddress?: boolean
  showAlert?: boolean
  showECNavigation?: boolean
  showLeadBar?: boolean
  showShareableBallotBanner?: boolean
  sticky?: boolean
}

const LocalNav = ({
  alert,
  auth,
  authEnabled = false,
  backButton = false,
  ballot,
  ballotEmailEnabled = false,
  ballotPrintEnabled = false,
  center = false,
  className,
  customButton,
  enableMaptv = false,
  enableVoterRegistration = false,
  onAlertClose,
  onLeadBarClose,
  onShareableBallotBannerClose,
  onUpdateAddress,
  history,
  homePath = '/',
  isCvg = false,
  isSmallScreen,
  lead,
  location,
  logoLinkUrl,
  logoUrl,
  showAddress = false,
  showAlert = false,
  showECNavigation,
  showLeadBar = false,
  showShareableBallotBanner = false,
  sticky = false,
}: Props) => {
  const { t } = useTranslation()
  const analytics = useAnalytics()

  const onClickMaptv = () => {
    analytics?.track('Nav bar: Clicked how to vote')
  }

  return (
    <div className={classNames('LocalNav__container', className)}>
      {showAlert && (
        <Notification
          className="LocalNav__GlobalAlert"
          dismissable
          onClose={onAlertClose}
          variant="warning"
        >
          <Markdown source={alert} />
        </Notification>
      )}

      {showShareableBallotBanner && (
        <Notification
          className="LocalNav__ShareableBallotBanner"
          dismissable
          onClose={onShareableBallotBannerClose}
          variant="success"
          withIcon={false}
        >
          {t('LocalNav.shareMotivation')}
          <Button icon={faShare} outline to="/v/share" variant="white">
            {t('LocalNav.share')}
          </Button>
        </Notification>
      )}

      {showLeadBar && <LeadBar lead={lead} onClose={onLeadBarClose} />}

      <nav
        className={classNames('LocalNav', {
          'LocalNav--center': center,
          'LocalNav--sticky': sticky,
        })}
      >
        {backButton && location.pathname !== homePath && (
          <Link
            className="LocalNav__Back"
            to={homePath}
            title={t('LocalNav.back')}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Link>
        )}

        <div className="LocalNav__BrandContainer">
          {showECNavigation && <ElectionCenterMobileNavButton />}

          {logoLinkUrl ? (
            <a
              className="LocalNav__Brand"
              href={logoLinkUrl}
              title={t('LocalNav.home')}
            >
              <Logo src={logoUrl} />
            </a>
          ) : (
            <BallotLink
              className="LocalNav__Brand"
              to={homePath}
              title={t('LocalNav.home')}
            >
              <Logo src={logoUrl} />
            </BallotLink>
          )}
        </div>

        <div className="LocalNav__Inner">
          <div className="LocalNav__FeatureButtons">
            {ballotPrintEnabled && isCvg && (
              <BallotLink
                className="Button Button--outline Button--default Button--sm"
                title={t('LocalNav.print')}
                to="/v/print"
              >
                <div className="Button__Inner">
                  <FontAwesomeIcon icon={faPrint} />
                </div>
              </BallotLink>
            )}

            {ballotEmailEnabled && isCvg && <EmailBallotButton />}

            {showAddress && ballot && (
              <small className="LocalNav__address">{ballot.address}</small>
            )}

            <AddressModal
              shouldRedirect={false}
              onUpdateAddress={onUpdateAddress}
            >
              {({ onClick }) => (
                <Button
                  className="LocalNav__BtnAddress"
                  onClick={onClick}
                  outline
                  variant="default"
                >
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    title={t('LocalNav.updateAddress')}
                  />
                </Button>
              )}
            </AddressModal>
          </div>

          {customButton && customButton.url && (
            <Button
              href={customButton.url}
              outline
              rel="noopener noreferrer"
              size="sm"
              target="_blank"
              variant="default"
            >
              {customButton.title}
            </Button>
          )}

          {enableVoterRegistration && (
            <Button
              className="LocalNav__BtnRegistration"
              outline={history.location.pathname !== '/v/check_registration'}
              size="sm"
              to="/v/check_registration"
              variant="default"
            >
              {t('LocalNav.registrationButton')}
            </Button>
          )}

          {enableMaptv &&
            auth.user &&
            auth.user.ballot_id &&
            ballot &&
            ballot.election_id && (
              <Button
                onClick={onClickMaptv}
                className="LocalNav__BtnMaptv"
                outline
                size="sm"
                to="/m"
                variant="default"
              >
                {t('LocalNav.maptvButton')}
              </Button>
            )}

          {authEnabled && !auth.isSignedIn && <AuthModal />}
          {authEnabled && auth.isSignedIn && (
            <SignOutButton outline size="sm" variant="default">
              {t('LocalNav.signOut')}
            </SignOutButton>
          )}
        </div>
      </nav>
      {/* {!isSmallScreen && showECNavigation && <ElectionCenterSubheader />} */}
    </div>
  )
}

export default localNavContainer(withRouter(LocalNav))
