import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChrome } from '@fortawesome/free-brands-svg-icons/faChrome'
import { faEdge } from '@fortawesome/free-brands-svg-icons/faEdge'
import { faFirefox } from '@fortawesome/free-brands-svg-icons/faFirefox'

import styles from './UnsupportedBrowserApp.module.css'

const UnsupportedBrowserApp = () => (
  <div className={styles.app}>
    <h1>Update your browser</h1>
    <p>
      You're using a version of Internet Explorer that isn't supported by this
      website. To get a better experience go to one of the following sites to
      install the latest version of your preferred browser.
    </p>

    <ul className={styles.browser}>
      <li>
        <a
          href="https://www.google.com/chrome/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={faChrome} size="2x" />
          Google Chrome
        </a>
      </li>
      <li>
        <a
          href="https://www.mozilla.org/firefox/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={faFirefox} size="2x" />
          Mozilla Firefox
        </a>
      </li>
      <li>
        <a
          href="https://www.microsoft.com/en-us/windows/microsoft-edge"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={faEdge} size="2x" />
          Microsoft Edge
        </a>
      </li>
    </ul>
  </div>
)

export default UnsupportedBrowserApp
