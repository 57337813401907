import React from 'react'
import Button from '../../sparkles/Button/Button'
import FetchingScene from '../../FetchingScene/FetchingScene'

const LoadingComponent = props => {
  if (props.error) {
    return (
      <FetchingScene>
        An error has occurred while loading the application...
        <Button onClick={props.retry} variant="primary">
          Retry
        </Button>
      </FetchingScene>
    )
  } else if (props.timedOut) {
    return (
      <FetchingScene>
        Loading the application is taking a long time...
        <Button onClick={props.retry} variant="primary">
          Retry
        </Button>
      </FetchingScene>
    )
  } else if (props.pastDelay) {
    return <FetchingScene>Loading...</FetchingScene>
  } else {
    return null
  }
}

export default LoadingComponent
