import React from 'react'
import { Field, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import Button from '../../sparkles/Button/Button'
import emailBallotFormContainer from './emailBallotFormContainer'
import './EmailBallotForm.css'
import useAnalytics from 'hooks/useAnalytics'

const EmailBallotForm = ({ onSubmit }) => {
  const { t } = useTranslation()
  const analytics = useAnalytics()

  const preSubmit = values => {
    analytics &&
      analytics.track('Email Ballot', {
        email: values.email,
      })
    onSubmit(values)
  }

  return (
    <Formik onSubmit={preSubmit} initialValues={{ email: undefined }}>
      {({ handleSubmit, isSubmitting }) => (
        <form className="EmailBallotForm" onSubmit={handleSubmit}>
          <Field
            className="EmailBallotForm__Email"
            name="email"
            placeholder={t(
              'EmailBallotButton.EmailBallotForm.placeholderEmail',
            )}
            type="email"
          />
          <Button
            className="EmailBallotForm__Submit"
            disabled={isSubmitting}
            type="submit"
            variant="danger"
          >
            {t('EmailBallotButton.EmailBallotForm.send')}
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default emailBallotFormContainer(EmailBallotForm)
