import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconLookup } from '@fortawesome/fontawesome-svg-core'
import Spinner from '../Spinner/Spinner'
import './Button.css'

export interface Props {
  block?: boolean
  btnRef?: React.RefObject<HTMLInputElement>
  busy?: boolean
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  form?: string
  href?: string
  icon?: IconLookup
  onClick?(event: any): void
  outline?: boolean
  rel?: string
  size?: string
  target?: string
  theme?: string
  to?: string
  type?: string
  variant?: string

  // Destructure dispatch so it doesn't get passed to child element
  dispatch?: any
}

export const ButtonSizes = Object.freeze({
  Large: 'lg',
  Small: 'sm',
})

export const ButtonVariants = Object.freeze({
  Black: 'black',
  Danger: 'danger',
  Default: 'default',
  Link: 'link',
  Panel: 'panel',
  Primary: 'primary',
  Secondary: 'secondary',
  Success: 'success',
  Warning: 'warning',
  White: 'white',
})

const Button = ({
  block,
  btnRef,
  busy = false,
  children,
  className,
  disabled = false,
  href,
  icon,
  onClick,
  outline = false,
  size,
  theme,
  to,
  type,
  variant,

  // Destructure dispatch so it doesn't get passed to child element
  dispatch,

  // Keep all other props to be passed to child
  ...props
}: Props) => {
  let elType: any = 'button' // TODO Type: string | typeof Link

  if (href) {
    elType = 'a'
  } else if (to) {
    elType = Link
  }

  const btnClassNames = classNames(
    'Button',
    {
      'Button--block': block,
      [`Button--${size}`]: size,
      [`Button--${variant}`]: variant,
      'Button--disabled': busy || disabled,
      'Button--outline': outline,
      'Button--with-icon': icon,
      'Button--with-icon-only': icon && !children,
      [`Button--${theme}`]: theme,
    },
    className,
  )

  return React.createElement(
    elType,
    {
      className: btnClassNames,
      disabled,
      href,
      onClick,
      ref: btnRef,
      to,
      type,
      ...props,
    },
    <div className="Button__inner">
      {busy && <Spinner size="sm" />}
      {icon && <FontAwesomeIcon icon={icon} />}
      {children}
    </div>,
  )
}

export default Button
