import { connect } from 'react-redux'

import signInAndReload from '../../actions/signInAndReload'
import toggleAuthModalIsOpen from 'actions/toggleAuthModalIsOpen'

const mapStateToProps = ({ appConfig: { logoUrl }, auth: { error } }) => ({
  error,
  logoUrl,
})

const mapDispatchToProps = (dispatch, { history }) => ({
  onLoginFailed: error => console.error(error),
  onLoginSuccess: (service, user) => {
    return dispatch(signInAndReload(history, service, user))
  },
  setAuthModalState: isOpen => dispatch(toggleAuthModalIsOpen(isOpen)),
})

export default connect(mapStateToProps, mapDispatchToProps)
