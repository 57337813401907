import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Spinner.css'

export const SpinnerSizes = Object.freeze({
  Large: 'lg',
  Small: 'sm',
})

const Spinner = ({ size }) => (
  <div
    className={classNames('Spinner', {
      [`Spinner--${size}`]: size,
    })}
  />
)

Spinner.propTypes = {
  size: PropTypes.oneOf([``].concat(Object.values(SpinnerSizes))),
}

export default Spinner
