import React, { Suspense } from 'react'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import FetchingScene from './components/FetchingScene/FetchingScene'
import ScrollToTop from './components/sparkles/ScrollToTop/ScrollToTop'
import Main from './components/Main/Main'
import createMyStore from './store'
import AnalyticsProvider from 'contexts/AnalyticsContext/AnalyticsProvider'
import qs from 'qs'

const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

const App = () => (
  <Suspense fallback={<FetchingScene />}>
    <Provider store={createMyStore()}>
      <AnalyticsProvider sessionId={params.sid} product="civic-engines">
        <Router>
          <ScrollToTop>
            <Sentry.ErrorBoundary showDialog>
              <Main />
            </Sentry.ErrorBoundary>
          </ScrollToTop>
        </Router>
      </AnalyticsProvider>
    </Provider>
  </Suspense>
)

export default App
