import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt'
import AddressForm from 'components/AddressForm/AddressForm'
import Modal from 'components/sparkles/Modal/Modal'
import addressModalContainer from './addressModalContainer'
import './AddressModal.scss'
import { Ballot } from '@ballotready/redux-api-client'

interface Props {
  ballot: Ballot
  children(props: { onClick(e: React.MouseEvent): void }): React.ReactNode
  shouldRedirect?: boolean
  onUpdateAddress?: () => void
}

const AddressModal: React.FC<Props> = ({
  ballot,
  children,
  shouldRedirect = true,
  onUpdateAddress,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { t } = useTranslation()

  if (!ballot) {
    return null
  }

  const handleSubmit = () => {
    onUpdateAddress && onUpdateAddress()
    setIsModalOpen(false)
  }

  return (
    <>
      {children({
        onClick: (e: React.MouseEvent) => {
          e.preventDefault()
          setIsModalOpen(true)
        },
      })}

      <Modal
        appElement={document.getElementById('root')}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <div className="AddressModal__CurrentAddress">
          <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
          {t('AddressModal.currentAddress')}:<strong>{ballot.address}</strong>
        </div>

        <AddressForm
          onSubmit={handleSubmit}
          showLabels={true}
          redirect={shouldRedirect}
        />
      </Modal>
    </>
  )
}

export default addressModalContainer(AddressModal)
