import React from 'react'
import classNames from 'classnames'
import { Link, withRouter } from 'react-router-dom'
import ballotLinkContainer from './ballotLinkContainer'
import {
  Candidate,
  Issue,
  Measure,
  Position,
} from '@ballotready/redux-api-client'
import { RouteComponentProps } from 'react-router'
import useAnalytics from 'hooks/useAnalytics'
import nameFormatter from 'utils/nameFormatter'

export interface Props extends RouteComponentProps {
  candidate?: Candidate | null
  className?: string | null
  dispatch?: any // TODO Real type
  issue?: Issue | null
  measure?: Measure | null
  position?: Position | null
  to?: any // TODO Real type (see LinkProps)

  [key: string]: any // Pass-through to Link
}

export const BallotLink: React.FC<Props> = ({
  // destructuring these props so they don't get passed to Link
  candidate,
  currentCandidate,
  currentPosition,
  className,
  dispatch,
  history,
  issue,
  location,
  match,
  measure,
  position,
  staticContext,
  to,
  onClick,

  // Any remaining props go to Link
  ...props
}) => {
  const analytics = useAnalytics()

  const outsideOnClick = () => {
    if (
      position &&
      candidate &&
      candidate.first_name &&
      candidate !== currentCandidate
    ) {
      analytics &&
        analytics.track('Viewed Candidate', {
          candidate: candidate && nameFormatter(candidate),
          candidate_id: candidate.id,
          position: position.name,
          position_id: position.id,
        })
    }
    if (measure && measure.name) {
      analytics &&
        analytics.track('Viewed Measure', {
          measure: measure.name,
          measure_id: measure.id,
        })
    }
    if (position && position.name && position !== currentPosition) {
      analytics &&
        analytics.track('Viewed position', {
          position: position.name,
          position_id: position.id,
        })
    }
    onClick()
  }
  return React.createElement(
    Link,
    Object.assign({ onClick: outsideOnClick }, props, {
      className: classNames('BallotLink', className),
      to,
    }),
  )
}
export default withRouter(ballotLinkContainer(BallotLink))
