import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { Lead } from '@ballotready/redux-api-client'
import LeadForm from '../LeadForm/LeadForm'
import './LeadBar.css'

interface Props {
  lead: Lead
  onClose(event: React.MouseEvent): void
}

const LeadBar = ({ lead, onClose }: Props) => {
  const { t } = useTranslation()

  return (
    <div className="LeadBar">
      <button className="Modal__BtnClose" onClick={onClose} title="Close">
        <FontAwesomeIcon icon={faTimes} />
      </button>

      {lead ? (
        <div className="LeadBar__Inner">
          <header className="LeadBar__Header">
            <h1>{t('LeadBar.submittedTitle')}</h1>
            <p>
              <Trans i18nKey="LeadBar.submittedTitle">
                We'll notify you of updates to your personal voter guide at
                <strong>{{ email: lead.email }}</strong>.
              </Trans>
            </p>
          </header>
        </div>
      ) : (
        <div className="LeadBar__Inner">
          <header className="LeadBar__Header">
            <h1>{t('LeadBar.title')}</h1>
            <p>{t('LeadBar.description')}</p>
          </header>

          <LeadForm askAddress className="LeadBar__LeadForm" />
        </div>
      )}
    </div>
  )
}

export default LeadBar
