import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import PlaceholderLogo from '../../../assets/images/logo.png'
import './Logo.css'

const Logo = ({ className, src }) => (
  <img
    alt="Logo"
    className={classNames('Logo', className)}
    src={src || PlaceholderLogo}
  />
)

Logo.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
}

export default Logo
