import { types as apiTypes } from '@ballotready/redux-api-client'
import * as types from 'actions/constants'

export default (state = null, action) => {
  switch (action.type) {
    case types.SELECT_POSITION:
      return action.position || null

    case apiTypes.RECEIVE_POSITION:
      return action.payload

    // When receiving a list of positions update locally selected position with
    // position from API.
    case apiTypes.RECEIVE_POSITIONS:
      if (state) {
        return (
          action.payload.find(
            position =>
              position.slug === state.slug || position.id === state.id,
          ) || null
        )
      } else {
        return state
      }

    default:
      return state
  }
}
