import { fetchPositionCandidates } from '@ballotready/redux-api-client'

export default positionId => async (dispatch, getState) => {
  const {
    appConfig: {
      showAllEndorsements,
      showUncertifiedCandidates,
      showVotingHistory,
    },
  } = getState()

  const options = {}

  if (showAllEndorsements) {
    options.include_all_endorsements = '1'
  }

  if (showUncertifiedCandidates) {
    options.include_uncertified_candidates = '1'
  }

  if (showVotingHistory) {
    options.check_or_x = '1'
  }

  return dispatch(fetchPositionCandidates(positionId, options))
}
