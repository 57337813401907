import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import LocalNav from '../LocalNav/LocalNav'
import notFoundSceneContainer from './notFoundSceneContainer'
import './NotFoundScene.css'

export const NotFoundScene = ({ tenant }) => {
  const { t } = useTranslation()

  return (
    <section className="NotFoundScene">
      <Helmet>
        <title>
          {t('NotFoundScene.title')} (404) - {tenant.name}
        </title>
      </Helmet>

      <LocalNav homePath="/" />

      <header className="NotFoundScene__Header">
        <h1>{t('NotFoundScene.title')}</h1>
        <p>{t('NotFoundScene.hint')}</p>
      </header>
    </section>
  )
}

export default notFoundSceneContainer(NotFoundScene)
