import React from 'react'
import { FieldProps } from 'formik'
import Select from 'react-select'

const SelectField = ({
  field, // { name, value, onChange, onBlur }
  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  options: Array<{ value: string; label: string }>
}) => {
  const { options } = props

  return (
    <Select
      {...field}
      {...props}
      options={options}
      value={
        (options
          ? options.find(option => option.value === field.value)
          : '') as any
      }
      onChange={option => setFieldValue(field.name, (option as any).value)}
    />
  )
}
export default SelectField
