import React from 'react'
import ReactMarkdown from 'react-markdown'

const Markdown: React.FC<{ [name: string]: any }> = props => (
  <ReactMarkdown
    {...props}
    renderers={{
      link: ({ href, children }) => (
        <a href={href} rel="noopener noreferrer" target="_blank">
          {children}
        </a>
      ),
    }}
  />
)

export default Markdown
