import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'

import './FacebookLoginButton.css'

class FacebookLoginButton extends React.Component {
  static propTypes = {
    onLoginFailed: PropTypes.func,
    onLoginSuccess: PropTypes.func.isRequired,
  }

  componentDidMount() {
    // If FB SDK initialized faster than our component we'll kick off component
    // init manually
    if (window.FB) {
      this.FB = window.FB
    }

    document.addEventListener('fbSdkReady', this.init)
  }

  componentWillUnmount() {
    document.removeEventListener('fbSdkReady', this.init)
  }

  /* Initializes FB login
   *
   * The FB SDK is passed in a CustomEvent in the FB SDK initializer code in
   * public/index.html. We'll save a reference for use in this component.
   *
   * We'll also check the current login status
   */
  init = e => {
    this.FB = e.detail
    this.checkLoginStatus()
  }

  checkLoginStatus = () => {
    this.FB.getLoginStatus(this.handleLogin)
  }

  /**
   * Check login status and call login api if user is not logged in
   */
  login = () => {
    if (!this.FB) {
      return
    }

    this.FB.getLoginStatus(response => {
      if (response.status === 'connected') {
        this.handleLogin(response)
      } else {
        this.FB.login(this.handleLogin, { scope: 'public_profile,email' })
      }
    })
  }

  handleLogin = response => {
    const { onLoginSuccess, onLoginFailed } = this.props

    if (response.status === 'connected') {
      this.FB.api('/me', user =>
        onLoginSuccess({
          auth_id: user.id,
          email: user.email,
          social_token: response.authResponse.accessToken,
        }),
      )
    } else if (onLoginFailed) {
      onLoginFailed(response)
    }
  }

  render() {
    if (!window.FB) {
      return null
    }

    // Weird __Content div because of Chrome/Webkit bug: https://bugs.chromium.org/p/chromium/issues/detail?id=375693
    return (
      <button className="FacebookLoginButton" onClick={this.login}>
        <div className="FacebookLoginButton__Content">
          <div className="FacebookLoginButton__icon">
            <FontAwesomeIcon icon={faFacebookF} />
          </div>
          <div className="FacebookLoginButton__inner">
            {this.props.children}
          </div>
        </div>
      </button>
    )
  }
}

export default FacebookLoginButton
