import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faConstruction } from '@fortawesome/pro-regular-svg-icons'
import Clock from 'components/sparkles/Clock/Clock'
import styles from './DebugPanel.module.scss'
import classNames from 'classnames'

const DebugPanel = () => {
  const [open, setOpen] = useState(false)

  return (
    <div
      className={classNames(styles.root, {
        [styles.open]: open,
      })}
    >
      <FontAwesomeIcon
        className={styles.icon}
        icon={faConstruction}
        onClick={() => setOpen(!open)}
        title="DEBUG MODE ENABLED"
      />
      <Clock />
    </div>
  )
}

export default DebugPanel
