import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'

import './Notification.css'

export interface Props {
  children: React.ReactNode
  className?: string
  dismissable?: boolean
  onClose?(): void
  size?: string
  variant?: string
  withIcon?: boolean
}

const Notification = ({
  children,
  className,
  dismissable = false,
  onClose,
  size,
  variant,
  withIcon = true,
}: Props) => (
  <div
    className={classNames(
      'Notification',
      {
        [`Notification--${size}`]: size,
        [`Notification--${variant}`]: variant,
      },
      className,
    )}
  >
    {withIcon && (
      <FontAwesomeIcon
        className="Notification__Icon"
        icon={variant === 'danger' ? faExclamationTriangle : faInfoCircle}
      />
    )}

    <div className="Notification__body">{children}</div>

    {dismissable && (
      <button
        className="Notification__close"
        onClick={onClose}
        title="Hide alert"
      >
        <FontAwesomeIcon icon={faTimes} size="2x" />
      </button>
    )}
  </div>
)

export default Notification
