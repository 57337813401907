import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactModal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'

import './Modal.css'

// http://reactcommunity.org/react-modal/accessibility/
// TODO Move somewhere else: ReactModal.setAppElement('#root')

const Modal = ({ appElement, children, className, isOpen, onClose }) => (
  <ReactModal
    appElement={appElement}
    className={{
      base: classNames('Modal', className),
      afterOpen: 'Modal--after-open',
      beforeClose: 'Modal--before-close',
    }}
    closeTimeoutMS={300}
    isOpen={isOpen}
    onRequestClose={onClose}
    overlayClassName={{
      base: 'Modal__Overlay',
      afterOpen: 'Modal__Overlay--after-open',
      beforeClose: 'Modal__Overlay--before-close',
    }}
  >
    {onClose && (
      <header className="Modal__Header">
        <button className="Modal__BtnClose" onClick={onClose} title="Close">
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </header>
    )}
    <section className="Modal__Body">{children}</section>
  </ReactModal>
)

Modal.propTypes = {
  appElement: PropTypes.instanceOf(HTMLElement),
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
}

Modal.defaultProps = {
  isOpen: false,
}

export default Modal
