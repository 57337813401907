import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Dashboard } from 'assets/images/dashboard.svg'
import styles from './ElectionCenterMobileNavButton.module.scss'

const ElectionCenterMobileNavButton = () => {
  return (
    <Link to="/ec" className={styles.ecNavButton}>
      <Dashboard />
    </Link>
  )
}

export default ElectionCenterMobileNavButton
